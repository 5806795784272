import { SimpleTreeView } from "@mui/x-tree-view";
import { useEffect, useState } from 'react';

import useFetch from 'hooks/useFetch';
import { deviceManagerAPI } from 'api';
import LocationItem from './LocationItem';
import { Asset, LocationNode, LocationTitle } from "types/custom";
import { buildSelectedPath } from "./utils";
import { API_URL_PATH_DM_DEVICE_LIST, API_URL_PATH_LOCATIONS_HIERARCHY } from 'constants/urls';
import { useDashboardContext } from "providers/DashboardProvider";
import { colors } from "../../../pages/dashboard/dashboard-page/utils";
import { makeNodeFromDevice } from "../hierarchy/utils";
import useFetchWithReactQuery from "hooks/useFetchWithReactQuery";
import { Alert, Box, Button } from "@cloudscape-design/components";

const LocationTree = () => {
  const {
    setSelectedPath,
    setSelectedNode,
    setChartDevices,
    chartDevices,
    setSelectedMeasurements,
  } = useDashboardContext();

  const [locations, setLocations] = useState<LocationNode>();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [expandWith, setExpandWith] = useState<string | null>(null);

  const {
    data: hierarchyLocation,
    isLoading: isHierarchyLocationLoading,
    error: hierarchyLocationError,
    isFetching: isHierarchyLocationFetching,
    refetch: refetchHierarchy
  } = useFetchWithReactQuery<LocationNode[]>({
    axiosInstance: deviceManagerAPI,
    url: API_URL_PATH_LOCATIONS_HIERARCHY,
    key: 'locations-hierarchy',
  });

  const { fetchData: getDevices, loading: isDevicesLoading } = useFetch(
    {
      axiosInstance: deviceManagerAPI,
      method: 'GET',
    },
    { manual: true }
  );

  const handleNodeClick = async (node: LocationNode) => {
    if (!hierarchyLocation) return;
    setSelectedNode(node);

    const path = buildSelectedPath(`${node?.title}Id=${node?.id}`, node?.parent || '', node?.parentTitle || '', hierarchyLocation);
    setSelectedPath(path);

    if (node.isDevice) {
      const availableColors = colors.filter(color => !chartDevices.some(device => device.color === color));
      setChartDevices([{ ...node, color: availableColors[0] }]);
      setSelectedMeasurements(new Map());
    } else if (node.title === 'room' && !node?.isRendered) {
      const response = await getDevices(`${API_URL_PATH_DM_DEVICE_LIST}?${path}`);
      const assets: Asset[] = response?.data?.items || [];
      if (assets.length > 0) {
        node.children = assets.map(makeNodeFromDevice);
        setExpandWith(`${node.id}room`);
      }
      node.isRendered = true;
    }
  };

  useEffect(() => {
    // This is used to ensure expanded will always have the latest available value
    if (expandWith) setExpanded([...expanded, expandWith]);
  }, [expandWith]);

  useEffect(() => {
    if (hierarchyLocation && hierarchyLocation.length > 0) {
      setLocations({
        id: '0',
        name: 'Installed devices',
        title: LocationTitle.LOCATION,
        children: hierarchyLocation,
      });

      //get all 1st child node id's to expand by default
      let expandedArr = [`0${LocationTitle.LOCATION}`];
      let stack = [];
      let node = [];

      stack.push(hierarchyLocation[0].children);
      expandedArr = [
        ...expandedArr,
        hierarchyLocation[0]?.id + hierarchyLocation[0]?.title,
      ];

      while (stack.length > 0) {
        node = stack.pop();

        if (node[0]?.children && node[0].children?.length > 0) {
          expandedArr = [...expandedArr, node[0]?.id + node[0].title];

          stack.push(node[0].children);
        }
      }

      setExpanded(expandedArr);
    }
  }, [hierarchyLocation]);

  return (
    <>
      {hierarchyLocationError && !isHierarchyLocationFetching && (
        <Alert type="error" header="Error">
          {String(hierarchyLocationError)}
        </Alert>
      )}

      {!locations?.children && !isHierarchyLocationLoading && !hierarchyLocationError && (
        <Alert type="info" header="No data available">
          Start installing devices to see them here.
        </Alert>
      )}

      {locations &&
        <SimpleTreeView
          onExpandedItemsChange={(_event, nodeIds) => setExpanded(nodeIds)}
          expandedItems={expanded}
          itemChildrenIndentation={8}
        >
          <LocationItem
            key={locations.id + locations.title}
            nodes={locations}
            getDevicesLoading={isDevicesLoading}
            handleNodeClick={handleNodeClick} />
        </SimpleTreeView>
      }
      <Box padding="xs" />
      <Button onClick={() => refetchHierarchy()} loading={isHierarchyLocationFetching} iconName="refresh" fullWidth>Refresh</Button>
    </>

  );
};

export default LocationTree;