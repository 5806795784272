import { Header, Grid, Container, SpaceBetween, ColumnLayout, ToggleButton } from '@cloudscape-design/components';

import AggregateData from 'components/dashboard-aggregate/AggregateData';
import LocationHierarchy from 'components/location-hierarchy/index';
import { useDashboardContext } from 'providers/DashboardProvider';
import DeviceCardContainer from 'components/device-card-container';
import DeviceChart from 'components/device-chart';
import { useEffect, useState } from 'react';
import PlaceIcon from '@mui/icons-material/Place';

const DashboardPage = () => {
  const { isUsingLocationsHierarchy, selectedNode, selectedMeasurements, dateTimeRange, layoutColumns } = useDashboardContext();
  const [zoomRange, setZoomRange] = useState<{
    min: number | undefined;
    max: number | undefined;
  }>({
    min: undefined,
    max: undefined,
  });
  const [aggregatedView, setAggregatedView] = useState(false);

  useEffect(() => {
    setZoomRange({
      min: undefined,
      max: undefined,
    });
  }, [dateTimeRange]);

  return (
    <Grid
      gridDefinition={[
        { colspan: { m: 3, xs: 4, default: 12 } },
        { colspan: { m: 9, xs: 8, default: 12 } },
      ]}
    >
      <Container header={<Header variant='h2' actions={
          isUsingLocationsHierarchy && <ToggleButton
            onChange={({ detail }) => setAggregatedView(detail.pressed)}
            pressed={aggregatedView}
            ariaLabel='Aggregated View'
            disabled={selectedNode?.isDevice}
            disabledReason='Select a location to view aggregated data'
            iconSvg={<PlaceIcon/>}
            pressedIconSvg={<PlaceIcon/>}
          />
      }>
        Devices Hierarchy
      </Header>}>
        <LocationHierarchy />
      </Container>

      <SpaceBetween size='m'>
        {aggregatedView && isUsingLocationsHierarchy && !selectedNode?.isDevice ? (
          <AggregateData />
        ) : (
          <>
            <DeviceCardContainer />
            <ColumnLayout columns={parseInt(layoutColumns) || 1}>
              {Array.from(selectedMeasurements).map(
                ([measurement, deviceIds]) =>
                  deviceIds.length > 0 && (
                    <DeviceChart
                      key={measurement}
                      measurement={measurement}
                      deviceIds={deviceIds}
                      zoomRange={zoomRange}
                      setZoomRange={setZoomRange}
                    />
                  ),
              )}
            </ColumnLayout>
          </>
        )}
      </SpaceBetween>
    </Grid>
  );
};

export default DashboardPage;
