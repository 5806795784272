import { DateRangePickerProps } from "@cloudscape-design/components";
import { relativeToSeconds } from "utils/relativeTime";

function getDateTimeRange(dateTimeRange: DateRangePickerProps.Value) {
  if (dateTimeRange.type === 'absolute') {
    return {
      from: Date.parse(dateTimeRange.startDate),
      to: Date.parse(dateTimeRange.endDate)
    }
  } else {
    return {
      from: Date.now() - relativeToSeconds(dateTimeRange) * 1000,
      to: Date.now(),
    }
  }
}

export default getDateTimeRange;
