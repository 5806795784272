import { DateRangePickerProps } from "@cloudscape-design/components";
import { createContext, useContext, useMemo, useState } from "react";
import { HierarchyNode, HierarchyNodeWithColor } from "types/custom";

const DashboardContext = createContext({
  isUsingLocationsHierarchy: false,
  setIsUsingLocationsHierarchy: (state: boolean) => { },
  dateTimeRange: {
    type: 'absolute',
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString()
  } as DateRangePickerProps.Value,
  setDateTimeRange: (state: DateRangePickerProps.Value) => { },
  chartDevices: [] as HierarchyNodeWithColor[],
  setChartDevices: (state: HierarchyNodeWithColor[]) => { },
  chartMeasurements: [] as string[],
  setChartMeasurements: (state: string[]) => { },
  selectedNode: {} as HierarchyNode | null,
  setSelectedNode: (state: HierarchyNode | null) => { },
  selectedPath: '',
  setSelectedPath: (state: string) => { },
  selectedMeasurements: {} as Map<string, string[]>,
  setSelectedMeasurements: (state: Map<string, string[]>) => { },
  rolesSelectDisabled: false,
  setRolesSelectDisabled: (state: boolean) => { },
  selectedRole: undefined as string | undefined,
  setSelectedRole: (state: string | undefined) => { },
  expandAll: false,
  setExpandAll: (state: boolean) => { },
  layoutColumns: '1',
  setLayoutColumns: (state: string) => { },
});

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }

  return context;
};

const DashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [isUsingLocationsHierarchy, setIsUsingLocationsHierarchy] = useState(false);
  const [dateTimeRange, setDateTimeRange] = useState<DateRangePickerProps.Value>({
    key: "previous-7-days",
    type: 'relative',
    amount: 7,
    unit: 'day',
  });
  const [chartDevices, setChartDevices] = useState<HierarchyNodeWithColor[]>([]);
  const [chartMeasurements, setChartMeasurements] = useState<string[]>([]);
  const [selectedNode, setSelectedNode] = useState<HierarchyNode | null>(null);
  const [selectedPath, setSelectedPath] = useState('');
  const [selectedMeasurements, setSelectedMeasurements] = useState<Map<string, string[]>>(new Map());
  const [rolesSelectDisabled, setRolesSelectDisabled] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string | undefined>(undefined);
  const [expandAll, setExpandAll] = useState(false);
	const [layoutColumns, setLayoutColumns] = useState('1');

  const values = useMemo(() => ({
    isUsingLocationsHierarchy,
    setIsUsingLocationsHierarchy,
    dateTimeRange,
    setDateTimeRange,
    chartDevices,
    setChartDevices,
    chartMeasurements,
    setChartMeasurements,
    selectedNode,
    setSelectedNode,
    selectedPath,
    setSelectedPath,
    selectedMeasurements,
    setSelectedMeasurements,
    rolesSelectDisabled,
    setRolesSelectDisabled,
    selectedRole,
    setSelectedRole,
    expandAll,
    setExpandAll,
    layoutColumns,
    setLayoutColumns,
  }), [
    isUsingLocationsHierarchy,
    setIsUsingLocationsHierarchy,
    dateTimeRange,
    setDateTimeRange,
    chartDevices,
    setChartDevices,
    chartMeasurements,
    setChartMeasurements,
    selectedNode,
    setSelectedNode,
    selectedPath,
    setSelectedPath,
    selectedMeasurements,
    setSelectedMeasurements,
    rolesSelectDisabled,
    setRolesSelectDisabled,
    selectedRole,
    setSelectedRole,
    expandAll,
    setExpandAll,
    layoutColumns,
    setLayoutColumns,
  ]);

  return (
    <DashboardContext.Provider value={values}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;