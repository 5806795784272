import { ContentLayout, Header } from '@cloudscape-design/components';

import AlertRulesManagerProvider from 'providers/AlertRulesManagerProvider';
import AlertRulesManagerPage from './alert-rules-manager-page';
import colors from 'theme/colors';

const AlertRulesManager = () => {
    return (
        <AlertRulesManagerProvider>
            <ContentLayout defaultPadding headerBackgroundStyle={colors.accentGreen2} header={<Header variant="h1">Alert Rules Manager</Header>}>
                <AlertRulesManagerPage />
            </ContentLayout>
        </AlertRulesManagerProvider>
    );
};

export default AlertRulesManager;
