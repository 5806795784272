import { useEffect, useState } from 'react';
import { NonCancelableCustomEvent, Select, SelectProps } from '@cloudscape-design/components';

import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { API_URL_PATH_UM_ROLES } from 'constants/urls';
import { UserRolesProps } from 'types/custom';
import { userManagerAPI } from 'api';

type RolePickerProps = {
	isDisabled?: boolean,
	onChangeRole: (state: string | undefined) => void,
	selectedRole?: string,
	defaultRole: string,
}

const RolePicker = ({ isDisabled, selectedRole, onChangeRole, defaultRole }: RolePickerProps) => {
	const [allRoles, setAllRoles] = useState<SelectProps.Options>([
		{ label: defaultRole, value: undefined, description: defaultRole },
	]);

	const { data: rolesResponse, error: rolesError, isLoading: rolesLoading, status: rolesStatus } = useFetchWithReactQuery({
		axiosInstance: userManagerAPI,
		url: API_URL_PATH_UM_ROLES,
		key: 'roles',
	});

	useEffect(() => {
		if (rolesStatus === "success") {
			setAllRoles([
				{ label: defaultRole, value: undefined, description: defaultRole },
				...rolesResponse?.items.map((role: UserRolesProps) => ({
					value: role.name,
					label: role.name,
					description: role.description,
				}))
			]);
		}
	}, [rolesStatus, rolesResponse, defaultRole]);

	const onRoleChangeHandler = (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
		onChangeRole(event.detail.selectedOption.value);
	}

	return (
		<Select
			selectedOption={selectedRole ? { value: selectedRole, label: selectedRole } : allRoles[0]}
			statusType={rolesError ? 'error' : rolesLoading ? 'loading' : undefined}
			disabled={isDisabled}
			loadingText='Loading roles'
			placeholder='Choose a role'
			filteringType='auto'
			onChange={onRoleChangeHandler}
			options={allRoles}
		/>
	)
}

export default RolePicker;