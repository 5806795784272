import { useMemo } from 'react';
import { Asset } from 'types/custom';

export interface Props {
    selectedDevices: Asset[]
    allDevices?: Asset[]
}

export default function useRemoveDuplicateMeasurements({selectedDevices, allDevices = []}: Props): string[] {
    const deviceMeasurements = useMemo<string[]>(() => {
        const devicesToUse = selectedDevices.length > 0 ? selectedDevices : allDevices;
        if (!devicesToUse.length)
        {
            return []
        }
        const measurementsWithDuplicates = devicesToUse.reduce(
            (prev, current) => [...prev, ...Object.keys(current.state)],
            [] as string[]
        );
        const uniqueMeasurementsSet = new Set(measurementsWithDuplicates);
        const uniqueMeasurements = Array.from(uniqueMeasurementsSet);
        return uniqueMeasurements;
    }, [selectedDevices, allDevices ]);

    return deviceMeasurements;
}
