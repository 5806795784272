import { Container, Spinner } from "@cloudscape-design/components";
import Toggle from "@cloudscape-design/components/toggle";
import { useState } from "react";

import useGetDeviceChart from "../../api/hooks/use-get-device-chart";
import CustomHeatmap from "../common/heatmap-chart";
import CustomLineChart from "../common/line-chart";
import { prepareChartData } from "./utils";

type DeviceChartProps = {
  measurement: string;
  deviceIds: string[];
  zoomRange: {
    min: number | undefined;
    max: number | undefined;
  };
  setZoomRange: (range: {
    min: number | undefined;
    max: number | undefined;
  }) => void;
};

const DeviceChart = ({ measurement, deviceIds, zoomRange, setZoomRange }: DeviceChartProps) => {
  const [isHeatmap, setIsHeatmap] = useState<boolean>(false);
  const { data, isLoading, isError } = useGetDeviceChart({ measurement, deviceIds });

  const hasData =
    data &&
    data.length > 0 &&
    data.every(
      (item) =>
        item &&
        Object.values(item).every((value) => value !== undefined)
    );

  return (
    <Container disableContentPaddings>
      {isLoading && (
        <div>
          <span style={{ justifyContent: "center", display: "flex", paddingTop: "20px", paddingBottom: "20px" }}>
            <Spinner />
          </span>
        </div>
      )}
      {hasData && !isLoading && (
        <div
          style={{
            padding: "14px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <span style={{ marginRight: "10px" }}>Line Chart</span>
            <Toggle
              checked={isHeatmap}
              onChange={() => setIsHeatmap(!isHeatmap)}
            />
            <span style={{ marginLeft: "10px" }}>Heatmap</span>
          </div>
          {hasData && !isHeatmap && (
            <CustomLineChart
              data={prepareChartData(data)}
              measurement={measurement}
              zoomRange={zoomRange}
              setZoomRange={setZoomRange}
            />
          )}
          {hasData && isHeatmap && (
            prepareChartData(data).map((chartData) => (
              <CustomHeatmap
                key={chartData.id}
                data={chartData}
                measurement={measurement}
              />
            ))
          )}
        </div>
      )}

      {!hasData && !isLoading && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px'
        }}>
          <p>{isError ? 'Error fetching data' : 'No data available for the selected date range'}</p>
        </div>
      )}
    </Container>
  );
};

export default DeviceChart;
