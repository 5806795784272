import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export interface CreateRoleFormValues {
  name: string;
  selectedRole: string;
  description: string;
  privilege: OptionDefinition | null;
  idpgroup: string;
  selectedDeviceGroups: OptionDefinition[];
  region: OptionDefinition | null;
  country: OptionDefinition | null;
  site: OptionDefinition | null;
};

export interface CreateRoleDto {
  name?: string;
  hierarchyProviderRole: string;
  description: string;
  privilege?: string;
  idpgroup: string;
  groupIds: (string | undefined)[];
  locations?: string[];
};

export const validateCreateRoleFormValues = (values: CreateRoleFormValues) => {
  const errors: Partial<Record<keyof typeof values, string>> = {};
  const isSuperAdmin = values.privilege?.value === 'super_admin';

  if (!values.name) {
    errors.name = 'Role name is required';
  }

  if (values.name.includes(' ')) {
    errors.name = 'Role name cannot contain spaces';
  }

  if (!values.privilege) {
    errors.privilege = 'Privilege is required';
  }

  if (!values.idpgroup) {
    errors.idpgroup = 'AD Group is required';
  }

  if (!values.selectedDeviceGroups[0]) {
    errors.selectedDeviceGroups = 'Device group is required';
  }

  if (!values.region && !isSuperAdmin) {
    errors.region = 'Region is required';
  }

  if (!values.country && !isSuperAdmin) {
    errors.country = 'Country is required';
  }

  if (!values.site && !isSuperAdmin) {
    errors.site = 'Site is required';
  }

  return errors;
}

export const privilegeOptions = [
  { label: 'Admin', value: 'ems_admin' },
  { label: 'Installer', value: 'ems_installer' },
  { label: 'User', value: 'ems_user' },
];