import { Header, SpaceBetween } from "@cloudscape-design/components";

import { useDashboardContext } from "providers/DashboardProvider";
import RolePicker from "components/role-picker/RolePicker";
import DateTimePicker from "components/date-time-picker";
import useAuth from "hooks/useAuth";
import LayoutPicker from 'components/layout-picker';

const DashboardHeader = () => {
  const { isUsingLocationsHierarchy, rolesSelectDisabled, setSelectedRole, selectedRole } = useDashboardContext();
  const { privileges } = useAuth();

  return (
    <Header variant="h1" actions={<SpaceBetween size='xs' direction='horizontal'>
      {privileges === 'super_admin' && !isUsingLocationsHierarchy &&
        <RolePicker
          isDisabled={rolesSelectDisabled}
          onChangeRole={setSelectedRole}
          selectedRole={selectedRole}
          defaultRole='Assigned Role'
        />
      }
      <DateTimePicker />
      <LayoutPicker/>
    </SpaceBetween>}>Dashboard</Header>
  );
};

export default DashboardHeader;