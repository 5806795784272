import { useState } from 'react';
import { Alert, Tabs } from '@cloudscape-design/components';

import { Asset } from 'types/custom';
import DeviceDetails from '../DeviceDetails';
import AlertsTable from '../DeviceAlertMgr/AlertsTable';
import IncidentsTable from '../DeviceIncidents/IncidentsTable';
import { extractMeasuresFromAllDevices } from 'components/device-manager/DeviceIncidents/IncidentsTable/utils';
import useRemoveDuplicateMeasurements from 'hooks/useDeviceMeasurements';
import { AlertRulesTableProvider } from 'providers/AlertRulesTableProvider';

type DeviceTabsProps = {
    selectedDevices: Asset[]
    isOnDeviceManagerPage?: boolean
    isInModal?: boolean
    initialTabId?: string
};

const DeviceTabs = ({ selectedDevices, isOnDeviceManagerPage, isInModal, initialTabId }: DeviceTabsProps) => {
    const [activeTabId, setActiveTabId] = useState(initialTabId ?? 'details');
    const measurements = useRemoveDuplicateMeasurements({selectedDevices})


    if (!selectedDevices || (Array.isArray(selectedDevices) && selectedDevices.length === 0)) {
        return (
            <Alert header="No device selected">Please select one to see details.</Alert>
        );
    }
    if (selectedDevices.length > 1) {
        return (
            <Alert header="Multiple devices selected">Please select only one to see details.</Alert>
        );
    }

    return (
        <Tabs
            tabs={[
                {
                    label: 'Details',
                    id: 'details',
                    content: <DeviceDetails selectedDevices={selectedDevices[0]} />,
                },
                {
                    label: 'Alert Rules',
                    id: 'alert-rules',
                    content:  <AlertRulesTableProvider  props={{isOnDeviceManagerPage: !!isOnDeviceManagerPage, isInModal: !!isInModal, selectedDevices, disableAllToggles: false}}><AlertsTable measurements={measurements} selectedDevices={selectedDevices} isInModal={isInModal} isOnDeviceManagerPage={isOnDeviceManagerPage} /></AlertRulesTableProvider>,
                },
                {
                    label: 'Incidents',
                    id: 'incidents',
                    content: <IncidentsTable
                        variant="embedded"
                        selectedDeviceProp={selectedDevices[0]}
                        allDevices={selectedDevices}
                        allMeasures={extractMeasuresFromAllDevices(selectedDevices)} />,
                }
            ]}
            activeTabId={activeTabId}
            onChange={(e) => setActiveTabId(e.detail.activeTabId)}
            variant='container'
        />
    );
};

export default DeviceTabs;
