import { snakeCaseToNormalCase } from "components/device-card/utils";
import { Asset, Incident, IncidentActivity } from "types/custom";

export const getLastEvent = (item: Incident) => {
  const event = (item.activities || []).map((activity) => [IncidentActivity.COMMENT, IncidentActivity.ACKNOWLEDGE, IncidentActivity.RESOLUTION].includes(activity.type)).lastIndexOf(true);
  return event !== -1 ? `${item.activities[event].value} at ${new Date(item.activities[event].time || NaN).toLocaleString(undefined, { timeZoneName: "shortOffset" })}` : undefined;
};

export function extractMeasuresFromAllDevices(allDevices: Asset[]) {
  const uniqueStates = new Set<string>();

  allDevices.forEach(device => {
    if (device.state && Object.keys(device.state).length > 0) {
      Object.keys(device.state).forEach(stateKey => {
        uniqueStates.add(stateKey);
      });
    }
  });

  const arrayOfOptions = Array.from(uniqueStates).map(x => ({ label: snakeCaseToNormalCase(x), value: x }));
  arrayOfOptions.unshift({ label: 'All', value: '' });

  return arrayOfOptions;
};