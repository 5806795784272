import {
    Button,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { userManagerAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import DeviceSelect from 'components/device-select';
import {
    API_URL_PATH_GROUP_LIST,
    URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST,
} from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useAlertRulesManagerContext } from 'providers/AlertRulesManagerProvider';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Asset, DeviceGroup } from 'types/custom';

type CreateDeviceGroupType = {
    setShowCreateDeviceGroupModal?: (state: boolean) => void;
    setShowCreateAlertModal?: (state: boolean) => void;
    isDeviceSelectDisabled?: boolean;
}

const CreateDeviceGroup = ({
    setShowCreateDeviceGroupModal,
    setShowCreateAlertModal,
    isDeviceSelectDisabled,
}: CreateDeviceGroupType) => {
    const { selectedDevices, setSelectedGroup } = useAlertRulesManagerContext();
    const [groupId, setGroupId] = useState('');
    const [disableFormFields, setDisableFormFields] = useState(false);
    const [friendlyName, setFriendlyName] = useState('');
    const [description, setDescription] = useState('');
    const { setNotification } = usePageLayoutContext();
    const navigate = useNavigate();

    const [selectedAssets, setSelectedAssets] = useState<
        readonly OptionDefinition[]
    >([]);

    useEffect(() => {
        if (selectedDevices) {
            setSelectedAssets(selectedDevices.map((device: Asset) => ({
                label: device.friendlyName,
                description: device.description,
                labelTag: device.name,
                value: device.name,
            })));
        }
    }, [])

    const {
        status: createDeviceGroupStatus,
        error: createDeviceGroupError,
        loading: createDeviceGroupLoading,
        fetchData: createDeviceGroup,
        response: createDeviceGroupResponse,
    } = useFetch(
        {
            axiosInstance: userManagerAPI,
            method: 'POST',
            url: API_URL_PATH_GROUP_LIST,
            data: {
                groupId,
                description,
                friendlyName,
                assets: selectedAssets?.map((asset) => ({
                    assetCategory: 'device',
                    assetId: asset.value || '',
                })) || [],
            },
        },
        { manual: true }
    );

    useEffect(() => {
        if (createDeviceGroupStatus > 200 && createDeviceGroupStatus < 300) {
            if (setShowCreateDeviceGroupModal && setShowCreateAlertModal) {
                setShowCreateDeviceGroupModal(false)
                setShowCreateAlertModal(true)

                if (createDeviceGroupResponse) {
                    setSelectedGroup(createDeviceGroupResponse as DeviceGroup);
                }
                setNotification([{
                    type: 'success',
                    content: `Successfully created new group`,
                }]);
                setDisableFormFields(false);

            }
            else {
                navigate(URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST, {
                    state: {
                        action: 'create-device-group',
                        status: 'success',
                        message: `Successfully created Device Group ${groupId}`,
                    },
                });

                setDisableFormFields(false);
            }
        }
    }, [createDeviceGroupStatus]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        await createDeviceGroup();
    };

    return (
        <Form
            actions={
                <SpaceBetween direction='horizontal' size='xs'>
                    <Button
                        formAction='none'
                        variant='link'
                        onClick={() => {
                            if (setShowCreateDeviceGroupModal) {
                                setShowCreateDeviceGroupModal(false)
                            } else {
                                navigate('/device-group-manager/list', {})
                            }
                        }
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='primary'
                        loading={createDeviceGroupLoading}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </SpaceBetween>
            }
            header={!setShowCreateDeviceGroupModal &&
                <Header variant='h2' description=''>
                    Create a Device Group
                </Header>
            }
            errorText={createDeviceGroupError}
        >
            <SpaceBetween direction='vertical' size='l'>
                <FormField label='Group Name'>
                    <Input
                        disabled={disableFormFields}
                        value={groupId}
                        onChange={(event) => setGroupId(event.detail.value)}
                    />
                </FormField>
                <FormField
                    label={
                        <span>
                            Description <i>- optional</i>{' '}
                        </span>
                    }
                >
                    <Input
                        disabled={disableFormFields}
                        value={description}
                        onChange={(event) =>
                            setDescription(event.detail.value)
                        }
                    />
                </FormField>
                <FormField label='Friendly Name'>
                    <Input
                        disabled={disableFormFields}
                        value={friendlyName}
                        onChange={(event) =>
                            setFriendlyName(event.detail.value)
                        }
                    />
                </FormField>

                <DeviceSelect
                    disabled={disableFormFields || !!isDeviceSelectDisabled}
                    selectedAssets={selectedAssets}
                    setSelectedAssets={setSelectedAssets}
                />
            </SpaceBetween>
        </Form>
    );
};

export default CreateDeviceGroup;
