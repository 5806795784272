import {
    Button,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Spinner,
} from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';

import useFetch from 'hooks/useFetch';
import { incidentManagerAPI } from 'api';
import DeleteModal from 'components/delete-modal';
import { API_URL_PATH_IM_GROUPS } from 'constants/urls';
import TransferList from 'components/incident-manager/TransferList';
import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';

const GroupsView = () => {
    const { selectedGroup, allContacts, groupsRefetch, groupsLoading, allGroups } =
        useNotificationManagerContext();
    const [groupName, setGroupName] = useState('');
    const [groupNameError, setGroupNameError] = useState('');
    const [description, setDescription] = useState('');
    const [flowType, setFlowType] = useState('update');
    const [disableFormFields, setDisableFormFields] = useState(false);
    const [contactsObj, setContactsObj] = useState([]);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDeleteDiscard = () => setShowDeleteModal(false);

    useEffect(() => {
        if (!allGroups || !allGroups?.length) {
            setFlowType('create');
        }
    }, [allGroups]);

    useEffect(() => {
        if (flowType === 'update') {
            setGroupName(selectedGroup?.name || '');
            setDescription(selectedGroup?.description || '');
        }
    }, [selectedGroup, flowType]);

    useEffect(() => {
        setGroupName(selectedGroup?.name || '');
    }, [selectedGroup]);

    const validateGroupName = () => {
        const groupNameRegExp = /^[a-z0-9_-](.|\s)*$/;
        const isValid = groupNameRegExp.test(groupName);

        if (groupName && isValid) {
            setGroupNameError('');
        } else {
            setGroupNameError('Enter a valid group name');
        }

        return isValid;
    };

    const {
        error: createGroupError,
        loading: createGroupLoading,
        status: createGroupStatus,
        fetchData: createGroup,
        response: createGroupResponse,
    } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'POST',
            url: API_URL_PATH_IM_GROUPS,
            data: {
                name: groupName,
                description,
                contacts: contactsObj,
            },
        },
        { manual: true }
    );

    const {
        error: updateGroupError,
        loading: updateGroupLoading,
        status: updateGroupStatus,
        fetchData: updateGroup,
        response: updateGroupResponse,
    } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'PATCH',
            url: `${API_URL_PATH_IM_GROUPS}/${selectedGroup?.name}`,
            data: {
                description,
                contacts: contactsObj,
            },
        },
        { manual: true }
    );

    const {
        error: deleteGroupError,
        loading: deleteGroupLoading,
        fetchData: deleteGroup,
    } = useFetch(
        {
            axiosInstance: incidentManagerAPI,
            method: 'DELETE',
            url: `${API_URL_PATH_IM_GROUPS}/${selectedGroup?.name}`,
        },
        { manual: true }
    );

    const handleCreateAction = (event: any) => {
        event.preventDefault();
        setFlowType('create');
        setGroupName('');
        setDescription('');
    };

    const handleDeleteAction = (event: any) => {
        event.preventDefault();
        setShowDeleteModal(true);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const groupNameValidation = validateGroupName();
        if (!groupNameValidation) return;

        setDisableFormFields(true);

        if (flowType === 'update') {
            updateGroup();
        } else {
            createGroup();
        }
    };

    useEffect(() => {
        setDisableFormFields(false);
        if (
            updateGroupStatus === 200 ||
            createGroupStatus === 201
            // deletePlanStatus === 200
        ) {
            groupsRefetch();
            setFlowType('update');
        } else if (
            updateGroupStatus > 201 ||
            createGroupStatus > 201
            // deletePlanStatus > 201
        ) {
            setDisableFormFields(false);
        }
    }, [
        createGroupStatus,
        createGroupResponse,
        updateGroupStatus,
        updateGroupResponse,
    ]);

    const onDeleteConfirm = () => {
        deleteGroup()
            .then(() => {
                groupsRefetch();
                setFlowType('update');
            })
            .catch((err) => {
                console.error(err);
            });
        setShowDeleteModal(false);
    };

    return (
        <div style={{ minHeight: '640px' }}>
            {groupsLoading && flowType === 'update' ? (
                <Spinner />
            ) : (
                <>
                    <form onSubmit={handleSubmit}>
                        <Form
                            actions={
                                <SpaceBetween direction='horizontal' size='xs'>
                                    {flowType === 'create' && (
                                        <Button
                                            formAction='none'
                                            variant='link'
                                            onClick={() => {
                                                setFlowType('update');
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                    <Button
                                        variant='primary'
                                        loading={
                                            createGroupLoading ||
                                            updateGroupLoading
                                        }
                                    >
                                        Save
                                    </Button>
                                </SpaceBetween>
                            }
                            header={
                                <Header
                                    variant='h3'
                                    actions={
                                        flowType !== 'create' && (
                                            <SpaceBetween
                                                direction='horizontal'
                                                size='xs'
                                            >
                                                <Button
                                                    variant='normal'
                                                    disabled={!selectedGroup}
                                                    onClick={handleDeleteAction}
                                                    loading={deleteGroupLoading}
                                                >
                                                    Delete
                                                </Button>
                                                <Button
                                                    variant='primary'
                                                    onClick={handleCreateAction}
                                                >
                                                    Create new
                                                </Button>
                                            </SpaceBetween>
                                        )
                                    }
                                >
                                    {flowType === 'update'
                                        ? 'Update'
                                        : 'Create'}{' '}
                                    Notification Group
                                </Header>
                            }
                            errorText={createGroupError || updateGroupError || deleteGroupError}
                        >
                            <SpaceBetween direction='vertical' size='l'>
                                <FormField
                                    label='Name'
                                    errorText={groupNameError}
                                >
                                    <Input
                                        disabled={
                                            disableFormFields ||
                                            flowType === 'update'
                                        }
                                        value={groupName}
                                        onChange={(event) =>
                                            setGroupName(event.detail.value)
                                        }
                                        onBlur={validateGroupName}
                                    />
                                </FormField>

                                <FormField label='Description'>
                                    <Input
                                        disabled={disableFormFields}
                                        value={description}
                                        onChange={(event) =>
                                            setDescription(event.detail.value)
                                        }
                                    />
                                </FormField>

                                <FormField label='Contacts' />
                                <TransferList
                                    allItems={allContacts}
                                    selectedItems={selectedGroup}
                                    itemType='groups'
                                    flowType={flowType}
                                    setChosen={setContactsObj}
                                />
                            </SpaceBetween>
                        </Form>
                    </form>

                    <DeleteModal
                        visible={showDeleteModal}
                        onDiscard={onDeleteDiscard}
                        onDelete={onDeleteConfirm}
                        itemName={[selectedGroup]}
                        itemCount={1}
                        moduleName='Notification Group'
                    />
                </>
            )}
        </div>
    );
};

export default GroupsView;
