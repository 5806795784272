import { LocationNode } from "types/custom";

const searchTreeById = (id: string, idTitle: string, treeArray: LocationNode[]) => {
  let stack: LocationNode[] = [...treeArray];
  let node: LocationNode | undefined;

  while (stack.length > 0) {
    node = stack.pop();
    if (node?.id === id && node?.title === idTitle) {
      return node;
    } else if (node?.children && node.children.length) {
      stack.push(...node.children);
    }
  }
  return null;
};

export const buildSelectedPath = (path: string, parent: string, parentTitle: string, hierarchyLocation: LocationNode[]) => {
  while (parent && parent !== 'location') {
    const pathItem = searchTreeById(parent, parentTitle, hierarchyLocation);

    if (pathItem) {
      path += `&${pathItem.title?.toLowerCase()}Id=${pathItem.id}`;
      parent = pathItem.parent!;
      parentTitle = pathItem.parentTitle!;
    }
  }

  return path;
};